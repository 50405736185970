/*
 * Login form / Logging out
 */

export const SUBMIT_LOG_IN_FORM = 'SUBMIT_LOG_IN_FORM'
export const SUBMIT_ADMIN_LOG_IN = 'SUBMIT_ADMIN_LOG_IN'
export const LOG_OUT = 'LOG_OUT'


/*
 * Dashboard
 */

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const SET_DASHBOARD_PERIOD = 'SET_DASHBOARD_PERIOD'

export const SET_DASHBOARD_BRANCH_ID = 'SET_DASHBOARD_BRANCH_ID'

/*
 * Profile
 */

export const FETCH_PROFILE = 'FETCH_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

/*
 * Categories
 */

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_CATEGORY_BY_ID = 'FETCH_CATEGORY_BY_ID'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

/*
 * Products
 */

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'


/*
 * Bundles
 */

export const FETCH_BUNDLES = 'FETCH_BUNDLES'
export const FETCH_BUNDLE_BY_ID = 'FETCH_BUNDLE_BY_ID'
export const UPDATE_BUNDLE = 'UPDATE_BUNDLE'
export const CREATE_BUNDLE = 'CREATE_BUNDLE'
export const DELETE_BUNDLE = 'DELETE_BUNDLE'

/*
 * Customers
 */

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const FETCH_CUSTOMER_BY_ID = 'FETCH_CUSTOMER_BY_ID'
export const FETCH_CUSTOMER_STATS_BY_ID = 'FETCH_CUSTOMER_STATS_BY_ID'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const SELECT_ALL = 'SELECT_ALL'
export const SELECT_ALL_ON_PAGE = 'SELECT_ALL_ON_PAGE'
export const DESELECT_ALL = 'DESELECT_ALL'
export const TOGGLE_SELECTION_BY_ID = 'TOGGLE_SELECTION_BY_ID'

/*
 * Employees
 */

export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES'
export const FETCH_EMPLOYEE_BY_ID = 'FETCH_EMPLOYEE_BY_ID'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'

/*
 * Partner Agents
 */

export const FETCH_PARTNER_AGENTS = 'FETCH_PARTNER_AGENTS'
export const FETCH_PARTNER_AGENT_BY_ID = 'FETCH_PARTNER_AGENT_BY_ID'
export const UPDATE_PARTNER_AGENT = 'UPDATE_PARTNER_AGENT'
export const CREATE_PARTNER_AGENT = 'CREATE_PARTNER_AGENT'
export const DELETE_PARTNER_AGENT = 'DELETE_PARTNER_AGENT'

/*
 * Payment Account
 */

export const FETCH_PAYMENT_ACCOUNTS = 'FETCH_PAYMENT_ACCOUNTS'
export const FETCH_PAYMENT_ACCOUNT_BY_ID = 'FETCH_PAYMENT_ACCOUNT_BY_ID'
export const UPDATE_PAYMENT_ACCOUNT = 'UPDATE_PAYMENT_ACCOUNT'
export const CREATE_PAYMENT_ACCOUNT = 'CREATE_PAYMENT_ACCOUNT'
export const DELETE_PAYMENT_ACCOUNT = 'DELETE_PAYMENT_ACCOUNT'


/*
 * Product Credit Transactions
 */

export const FETCH_PRODUCT_CREDIT_TRANSACTIONS = 'FETCH_PRODUCT_CREDIT_TRANSACTIONS'
export const FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID = 'FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID'
export const UPDATE_PRODUCT_CREDIT_TRANSACTION = 'UPDATE_PRODUCT_CREDIT_TRANSACTION'
export const CREATE_PRODUCT_CREDIT_TRANSACTION = 'CREATE_PRODUCT_CREDIT_TRANSACTION'
export const DELETE_PRODUCT_CREDIT_TRANSACTION = 'DELETE_PRODUCT_CREDIT_TRANSACTION'


/*
 * Vehicles
 */

export const FETCH_VEHICLES = 'FETCH_VEHICLES'
export const FETCH_VEHICLE_BY_ID = 'FETCH_VEHICLE_BY_ID'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const CREATE_VEHICLE = 'CREATE_VEHICLE'
export const DELETE_VEHICLE = 'DELETE_VEHICLE'

/*
 * Branches
 */
export const UPDATE_BRANCH = 'UPDATE_BRANCH'


/*
 * SMS Blasts
 */

export const FETCH_SMS_BLASTS = 'FETCH_SMS_BLASTS'
export const FETCH_SMS_BLAST_BY_ID = 'FETCH_SMS_BLAST_BY_ID'
export const UPDATE_SMS_BLAST = 'UPDATE_SMS_BLAST'
export const CREATE_SMS_BLAST = 'CREATE_SMS_BLAST'
export const DELETE_SMS_BLAST = 'DELETE_SMS_BLAST'
export const SEND_SMS_BLAST = 'SEND_SMS_BLAST'


/*
 * Promos
 */

export const FETCH_PROMOS = 'FETCH_PROMOS'
export const FETCH_PROMO_BY_ID = 'FETCH_PROMO_BY_ID'
export const UPDATE_PURCHASE_PROMO = 'UPDATE_PURCHASE_PROMO'
export const CREATE_PURCHASE_PROMO = 'CREATE_PURCHASE_PROMO'
export const UPDATE_LOYALTY_PROMO = 'UPDATE_LOYALTY_PROMO'
export const CREATE_LOYALTY_PROMO = 'CREATE_LOYALTY_PROMO'
export const DELETE_PROMO = 'DELETE_PROMO'

/*
 * Invoices
 */

export const FETCH_INVOICES = 'FETCH_INVOICES'
export const FETCH_INVOICE_BY_ID = 'FETCH_INVOICE_BY_ID'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const UPDATE_INVOICE_PAYMENT = 'UPDATE_INVOICE_PAYMENT'
export const FULLY_REFUND_INVOICE = 'FULL_REFUND_INVOICE'
export const MARK_INVOICES_FINALIZED = 'MARK_INVOICES_FINALIZED'


/*
 * Payments
 */

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS'
export const FETCH_PAYMENT_BY_ID = 'FETCH_PAYMENT_BY_ID'
export const CREATE_PAYMENT = 'CREATE_PAYMENT'

/*
 * UI
 */

export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE'
export const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE'
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION'

export const CLEAR_FLASH_MESSAGES = 'CLEAR_FLASH_MESSAGES'